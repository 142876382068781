import React from 'react';
import { Button } from '../../Button';
import { ButtonProps } from 'wix-ui-tpa/dist/src/components/Button/Button';

interface Props extends ButtonProps {
  className?: string;
  label: string;
}

export const CTA: React.FC<Props> = ({ className, label, ...btnProps }) => {
  return (
    <div className={className}>
      <Button data-hook="cta-button" fullWidth {...btnProps}>
        {label}
      </Button>
    </div>
  );
};

CTA.displayName = 'CTA';
