import React from 'react';

import { ModalV2, TPAModalProps } from '../Modal';
import { TEXT_BUTTON_PRIORITY, TextButton } from 'wix-ui-tpa/TextButton';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { Button } from '../Button';

import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import {
  MembershipQuestionAnswers,
  ValidationError,
} from '@wix/social-groups-api/dist/src/model/MembershipAnswers/MembershipQuestionAnswers';
import { QuestionAnswer } from './MembershipQuestion/QuestionAnswer';
import { Spinner } from '../Spinner';
import { st, classes } from './MQModal.st.css';
import classnames from 'classnames';
import { MarginDivider } from '../MarginDivider';
import {
  InjectedBiLoggerProps,
  withBi,
  WithTranslation,
  withTranslation,
} from '@wix/yoshi-flow-editor';
import { compose } from '../../utils/compose';
import {
  groupMembershipQuestionsMembershipQuestionsScreenLoadForMember,
  groupMembershipQuestionsMemberSubmitCloseAnswers,
} from '@wix/bi-logger-groups/v2';
import { withTheme, Theme } from '../../context/theme';

interface MembershipQuestionsModalComponentProps extends TPAModalProps {
  questions: ApiTypes.v1.Question[];
  groupName: string;
  groupId: string;
  onSubmit(answers: ApiTypes.v1.QuestionAnswer[]): void;
  onViewGroupClick?(): void;
}

type MembershipQuestionsModalProps = MembershipQuestionsModalComponentProps &
  InjectedBiLoggerProps &
  Theme &
  WithTranslation;

interface State {
  editingQAId: string;
}

class MembershipQuestionsModalComponent extends React.Component<
  MembershipQuestionsModalProps,
  State
> {
  state: State = {
    editingQAId: null as any,
  };
  private qa!: MembershipQuestionAnswers;
  private invalidAnswers: Map<string, ValidationError> = new Map();

  componentDidUpdate(prevProps: MembershipQuestionsModalProps) {
    // when modal opened
    if (!prevProps.isOpen && this.props.isOpen) {
      const { bi } = this.props;
      bi.report(
        groupMembershipQuestionsMembershipQuestionsScreenLoadForMember({
          groupId: this.props.groupId,
          origin: 'member_questions_screen',
        }),
      );
    }
  }

  render() {
    const {
      questions,
      t,
      groupName,
      onViewGroupClick,
      className,
      forceBlackAndWhite,
      ...modalProps
    } = this.props;
    return (
      <ModalV2
        {...(modalProps as any)}
        onRequestClose={this.onClose}
        className={classnames(classes.root, className)}
      >
        <ModalV2.Title>
          {t('groups-web.membership-questions.title')}
        </ModalV2.Title>
        <ModalV2.Subtitle>
          {t('groups-web.membership-questions.subtitle', {
            groupName,
          })}
        </ModalV2.Subtitle>
        <ModalV2.Content className={classes.content}>
          {this.renderQuestionAnswers()}
        </ModalV2.Content>
        <ModalV2.Footer
          okButton={
            <Button
              forceBlackAndWhite={forceBlackAndWhite}
              upgrade={true}
              priority={PRIORITY.primary}
              onClick={this.onSubmit}
            >
              {t('groups-web.submit')}
            </Button>
          }
          cancelButton={
            <Button
              forceBlackAndWhite={forceBlackAndWhite}
              upgrade={true}
              priority={PRIORITY.secondary}
              onClick={this.onClose}
            >
              {t('groups-web.cancel')}
            </Button>
          }
          sideAction={
            onViewGroupClick ? (
              <TextButton
                className={st(classes.viewGroupLink, {
                  bw: !!forceBlackAndWhite,
                })}
                priority={TEXT_BUTTON_PRIORITY.link}
                onClick={onViewGroupClick}
              >
                {t('groups-web.membership-questions.view-group')}
              </TextButton>
            ) : null
          }
        />
      </ModalV2>
    );
  }

  onAnswerChange = (questionId: string, answer: string) => {
    this.qa.answer(questionId, answer);
    if (this.state.editingQAId !== questionId) {
      this.setState({ editingQAId: questionId });
    }
  };

  onAnswerBlur = (questionId: string) => {
    // validate
    this.invalidAnswers.clear();
    const validationResult = this.qa.validate(questionId);
    if (validationResult !== null) {
      this.invalidAnswers.set(questionId, validationResult);
    }
    this.setState({ editingQAId: null as any });
  };

  onSubmit = () => {
    const { onSubmit, bi, groupId } = this.props;
    if (this.allValid()) {
      const answers = this.qa.getQuestionIdAnswers();
      bi.report(
        groupMembershipQuestionsMemberSubmitCloseAnswers({
          action: 'submit',
          countQuestions: answers.length,
          groupId,
          origin: 'member_questions_screen',
        }),
      );
      onSubmit(answers);
      this.setState({ editingQAId: null as any });
      return;
    }
    // @ts-expect-error
    const firstInvalid = this.invalidAnswers.keys()[0];
    this.setState({ editingQAId: firstInvalid });
  };

  allValid(): boolean {
    this.invalidAnswers = this.qa
      .getAllAnswers()
      .reduce((invalidAnswers, qa) => {
        const questionId = qa.question?.questionId;
        const validation = this.qa.validate(questionId!);

        if (validation !== null) {
          invalidAnswers.set(questionId!, validation);
        }
        return invalidAnswers;
      }, new Map<string, ValidationError>());

    return !this.invalidAnswers.size;
  }

  onClose = () => {
    // do not show validation
    this.setState({ editingQAId: null as any });
    this.qa = null as any;
    this.invalidAnswers.clear();
    const { bi } = this.props;
    bi.report(
      groupMembershipQuestionsMemberSubmitCloseAnswers({
        action: 'close',
        origin: 'member_questions_screen',
      }),
    );
    this.props.onRequestClose();
  };

  private renderQuestionAnswers() {
    const { questions } = this.props;
    if (!questions) {
      return <Spinner offset="L" />;
    }
    if (!this.qa) {
      this.qa = MembershipQuestionAnswers.fromQuestions(questions);
    }
    return this.qa.getAllAnswers().map((qa, i, { length }) => {
      const id = qa.question?.questionId;
      const error = this.invalidAnswers.get(id!);
      const forceFocus = error !== undefined && this.state.editingQAId === id;
      const isLast = length - 1 === i;

      return (
        <>
          <QuestionAnswer
            key={id}
            index={i + 1}
            questionAnswer={qa}
            error={error}
            onChange={this.onAnswerChange}
            onBlur={this.onAnswerBlur}
            forceFocus={forceFocus}
          />
          {!isLast && <MarginDivider marginTop={20} marginBottom={20} />}
        </>
      );
    });
  }
}

const enhance = compose(withTranslation(), withBi, withTheme);

export const MembershipQuestionsModal = enhance(
  MembershipQuestionsModalComponent,
) as React.ComponentType<MembershipQuestionsModalComponentProps>;
