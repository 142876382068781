import React from 'react';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { Modal, ModalProps } from '../Modal';
import { DATA_HOOKS } from './dataHooks';
import { Button } from '../Button';
import { Theme, withTheme } from '../../context/theme';

export interface DialogProps extends ModalProps {
  title?: string;
  caption?: string;
  cancelLabel?: string;
  okLabel?: string;
  onOkClick?(): void;
  onCancelClick?(): void;
  buttons?: React.ReactNode;
}

const DialogComponent: React.FC<DialogProps & Theme> = (props) => {
  const {
    isOpen,
    onRequestClose,
    title,
    caption,
    cancelLabel,
    okLabel,
    onCancelClick,
    onOkClick,
    buttons,
    children,
    dataHook,
    forceBlackAndWhite,
  } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} dataHook={dataHook}>
      {title ? <Modal.Header title={title} subtitle={caption} /> : null}
      {children}
      <Modal.Buttons>
        {buttons || (
          <>
            <Button
              forceBlackAndWhite={forceBlackAndWhite}
              onClick={onCancelClick || onRequestClose}
              priority={PRIORITY.secondary}
              data-hook={DATA_HOOKS.cancelButton}
            >
              {cancelLabel || 'Cancel'}
            </Button>
            <Button
              forceBlackAndWhite={forceBlackAndWhite}
              onClick={onOkClick}
              priority={PRIORITY.primary}
              data-hook={DATA_HOOKS.okButton}
            >
              {okLabel || 'OK'}
            </Button>
          </>
        )}
      </Modal.Buttons>
    </Modal>
  );
};
export const Dialog = withTheme(
  DialogComponent,
) as React.ComponentType<DialogProps>;
Dialog.displayName = 'Dialog';
