import React from 'react';
import { classes } from './GroupList.st.css';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';

interface NoGroupsProps {
  emptyStateHeader: string;
  emptyStateText: string;
  actionButton?: JSX.Element;
}

export const NoGroups: React.FC<NoGroupsProps> = ({
  emptyStateHeader,
  emptyStateText,
  actionButton,
}) => {
  return (
    <div className={classes.emptyRoot}>
      <Text
        typography={TYPOGRAPHY.smallTitle}
        className={classes.emptyStateHeader}
      >
        {emptyStateHeader}
      </Text>
      <Text
        typography={TYPOGRAPHY.runningText}
        className={classes.emptyStateText}
      >
        {emptyStateText}
      </Text>
      {actionButton}
    </div>
  );
};
