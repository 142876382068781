import React from 'react';

import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';

import { SpinnerIcon } from './SpinnerIcon';
import { st, classes } from './Spinner.st.css';

export interface SpinnerProps {
  label?: string;
  offset?: 'NONE' | 'S' | 'M' | 'L';
  forceBlackAndWhite?: boolean;
}

export const Spinner: React.FC<SpinnerProps> = ({
  label,
  offset,
  forceBlackAndWhite,
}) => (
  <div
    className={st(classes.root, { offset, bw: !!forceBlackAndWhite } as any)}
  >
    <SpinnerIcon width={20} height={20} className={classes.spinner} />
    <Text className={classes.label} typography={TYPOGRAPHY.runningText}>
      {label}
    </Text>
  </div>
);

Spinner.displayName = 'Spinner';
