import React from 'react';
import classname from 'classnames';
import { Text } from 'wix-ui-tpa/Text';
import { Input } from 'wix-ui-tpa/Input';
import { Button, PRIORITY } from 'wix-ui-tpa/Button';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { GroupTypesDropdown } from './GroupTypesDrowdown';
import { GroupVisibilityDropdown } from './GroupVisibilityDropdown';

import { TPAComponentsConfig } from 'wix-ui-tpa/TPAComponentsConfig';
import { DATA_HOOKS } from './dataHooks';

import { st, classes } from './CreateGroupModal.st.css';
import {
  InjectedExperimentsProps,
  withExperiments,
  withTranslation,
  WithTranslation,
} from '@wix/yoshi-flow-editor';
import { compose } from '../../../../../common/utils/compose';
import { withGroupsListContext } from '../../../contexts/withGroupsListContext';
import { withTPAConfig } from '../../../../../common/components/withTPAConfig';
import { CreateGroupAction } from '../../../../../common/types/groups-list/types';
import {
  TPAModal,
  TPAModalProps,
} from '../../../../../common/components/Modal/TPAModal';
import { GroupLogoUpload } from '../../GroupLogoUpload/GroupLogoUpload';
import { Loader } from '../../../../../common/components/Loader/Loader';
import { Theme, withTheme } from '../../../../../common/context/theme';
import { IGroupsListContext } from '../../../../../common/context/groups-list/IGroupsListContext';

const SOCIAL_GROUP_NAME_MAX_LENGTH = 50;
const DEFAULT_GROUP_PRIVACY_LEVEL = ApiTypes.v1.GroupPrivacyLevel.PUBLIC;

export interface CreateGroupModalProps
  extends TPAModalProps,
    CreateGroupAction {
  withPendingGroupExplanation?: boolean; // TODO add
}

interface CreateGroupState {
  title: string;
  privacyLevel: ApiTypes.v1.GroupPrivacyLevel;
}

type Props = CreateGroupModalProps &
  WithTranslation &
  IGroupsListContext &
  Theme &
  TPAComponentsConfig &
  InjectedExperimentsProps;

export class CreateGroupModal extends React.Component<Props, CreateGroupState> {
  static displayName = 'CreateGroupModal';
  private readonly defaultState = {
    title: '',
    privacyLevel: DEFAULT_GROUP_PRIVACY_LEVEL,
  };
  private groupImage!: File;
  readonly state = this.defaultState;

  UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
    if (!nextProps.isOpen && this.props.isOpen) {
      this.setState(this.defaultState);
    }
  };

  handleOnSubmit = (e: any) => {
    e.preventDefault();
    const { title, privacyLevel } = this.state;
    if (this.isValidTitle() && !this.props.isGroupCreating) {
      this.props.createGroup({ title }, { privacyLevel }, this.groupImage);
    }
  };

  isValidTitle() {
    const { title } = this.state;
    return !!title;
  }

  setSocialGroupName = (e: any) => {
    this.setState({
      title: e.target.value,
    });
  };

  setSocialGroupType = (option: any) => {
    this.setState({ privacyLevel: option.id });
  };

  openDashboard = () => {
    try {
      const { dashboardLink } = this.props;

      if (!dashboardLink) {
        return;
      }

      window.open(dashboardLink);
    } catch (e) {
      console.error('Failed to open dashboard', e);
    }
  };

  render() {
    const {
      t,
      onRequestClose,
      isGroupCreating,
      isOpen,
      mobile,
      withPendingGroupExplanation,
      forceBlackAndWhite,
      experiments,
      dashboardLink,
    } = this.props;
    const { title, privacyLevel } = this.state;

    const isDisabledCreateButton = !this.isValidTitle() || isGroupCreating;
    const groupName = 'groupName';

    const newJoinFlowEnabled = experiments.enabled('specs.groups.NewJoinFlow');
    const shouldShowGroupVisibility =
      newJoinFlowEnabled &&
      privacyLevel !== ApiTypes.v1.GroupPrivacyLevel.PUBLIC;

    return (
      <TPAModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        inFullScreen={mobile}
        className={st(classes.root, {
          mobile,
          bw: forceBlackAndWhite,
          withPendingGroupExplanation,
        } as any)}
      >
        <form onSubmit={this.handleOnSubmit} data-hook={DATA_HOOKS.root}>
          <Text className={classes.title}>
            {t('groups-web.modals.create-group.title')}
          </Text>
          <GroupLogoUpload
            forceBlackAndWhite={forceBlackAndWhite}
            className={classes.logoUpload}
            uploadImage={this.setImage}
          />

          <div className={classes.nameControl}>
            <Text className={classes.label}>
              <label htmlFor={groupName}>
                {t('groups-web.modals.create-groups.groupName')}
              </label>
              <span className={classes.counter}>
                {title.length}/{SOCIAL_GROUP_NAME_MAX_LENGTH}
              </span>
            </Text>
            <Input
              className={classes.groupNameInput}
              data-hook={DATA_HOOKS.groupName}
              maxLength={SOCIAL_GROUP_NAME_MAX_LENGTH}
              placeholder={t(
                'groups-web.modals.create-groups.groupName.placeholder',
              )}
              onChange={this.setSocialGroupName}
              id={groupName}
            />
          </div>

          <div className={classes.privacyControl}>
            <Text className={classes.label}>
              <span>{t('groups-web.modals.create-groups.groupType')}</span>
            </Text>
            <div>
              <GroupTypesDropdown
                forceBlackAndWhite={forceBlackAndWhite}
                selectedType={privacyLevel}
                onSelect={this.setSocialGroupType}
              />
            </div>
            {withPendingGroupExplanation ? (
              <Text
                className={classname(
                  classes.label,
                  classes.pendingGroupExplanation,
                )}
              >
                {t('groups-web.modals.create-group.pending-group-explanation')}
              </Text>
            ) : null}
          </div>

          {shouldShowGroupVisibility && (
            <div className={classes.groupVisibility}>
              <Text className={classes.label}>
                <span>
                  {t('groups-web.modals.create-groups.groupVisibility')}
                </span>
              </Text>
              <div>
                <GroupVisibilityDropdown
                  forceBlackAndWhite={forceBlackAndWhite}
                  selectedType={privacyLevel}
                  onSelect={this.setSocialGroupType}
                />
              </div>
              {!!dashboardLink && (
                <div className={classes.goToDashboard}>
                  <Text>
                    {t('groups-web.modals.create-groups.manageAccessFrom')}
                  </Text>
                  <Button
                    as="a"
                    className={classes.goToDashboardButton}
                    priority={PRIORITY.secondary}
                    href={dashboardLink}
                  >
                    {t('groups-web.modals.create-groups.manageAccessDashboard')}
                  </Button>
                </div>
              )}
            </div>
          )}

          <div className={classes.buttons}>
            <div className={classes.buttonWrapper}>
              <Button
                className={classes.cancelButton}
                data-hook={DATA_HOOKS.cancelButton}
                priority={PRIORITY.secondary}
                onClick={onRequestClose}
              >
                {t('groups-web.btn.cancel')}
              </Button>
            </div>
            <Button
              className={classes.createButton}
              data-hook={DATA_HOOKS.createButton}
              priority={PRIORITY.primary}
              type="submit"
              disabled={isDisabledCreateButton}
              prefixIcon={isGroupCreating ? <Loader /> : undefined}
            >
              {isGroupCreating
                ? t('groups-web.btn.creating')
                : t('groups-web.btn.create')}
            </Button>
          </div>
        </form>
      </TPAModal>
    );
  }

  private readonly setImage = (image: File) => {
    this.groupImage = image;
  };
}

const enhance = compose(
  withGroupsListContext,
  withTPAConfig,
  withTranslation(),
  withTheme,
  withExperiments,
);

export default enhance(
  CreateGroupModal,
) as React.ComponentType<CreateGroupModalProps>;
