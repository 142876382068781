import React from 'react';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import {
  Dropdown,
  DropdownOptionProps,
  DropdownProps,
} from 'wix-ui-tpa/Dropdown';

import { st, classes } from './GroupTypeSelect.st.css';
import { Private, Public, Secret } from '../Icons';
import { useTranslation } from '@wix/yoshi-flow-editor';

export type GroupTypesTranslationKeys = {
  [id in ApiTypes.v1.GroupPrivacyLevel]?: {
    value: string;
    subtitle: string;
  };
};
const groupTypeIcons: {
  [id in ApiTypes.v1.GroupPrivacyLevel]: React.ReactNode;
} = {
  [ApiTypes.v1.GroupPrivacyLevel.PUBLIC]: <Public />,
  [ApiTypes.v1.GroupPrivacyLevel.PRIVATE]: <Private />,
  [ApiTypes.v1.GroupPrivacyLevel.SECRET]: <Secret />,
};

export interface GroupTypesProps {
  selectedType: ApiTypes.v1.GroupPrivacyLevel;
  translationKeys: GroupTypesTranslationKeys;
  forceBlackAndWhite?: boolean;

  onSelect(option: GroupTypeSelectOption): void;
}

export interface GroupTypeSelectOption extends DropdownOptionProps {
  value: ApiTypes.v1.GroupPrivacyLevel;
}

interface GroupTypeSelectProps
  extends GroupTypesProps,
    Partial<DropdownProps> {}

export const GroupTypeSelect: React.FC<GroupTypeSelectProps> = (props) => {
  const { t } = useTranslation();

  const getGroupTypeOptions = (): GroupTypeSelectOption[] => {
    const { translationKeys } = props;

    return Object.keys(translationKeys).map((key) => {
      // @ts-expect-error
      const icon = groupTypeIcons[key];
      // @ts-expect-error
      const { value, subtitle } = translationKeys[key];

      const optionDataHookPrefix = 'group-privacy-option-';
      const dataHook = `${optionDataHookPrefix}${key}`;

      return {
        id: key,
        isSelectable: true,
        value: t(value),
        subtitle: t(subtitle),
        icon: (
          <div className={classes.groupTypeIcon} data-hook={dataHook}>
            {icon}
          </div>
        ),
      };
    });
  };

  const { onSelect, selectedType, forceBlackAndWhite } = props;
  const options = getGroupTypeOptions();

  const dataHook = 'group-privacy-dropdown';

  return (
    <Dropdown
      upgrade
      className={st(classes.root, { bw: !!forceBlackAndWhite })}
      data-hook={dataHook}
      options={options}
      onChange={onSelect}
      initialSelectedId={selectedType}
    />
  );
};

GroupTypeSelect.displayName = 'GroupTypeSelect';
